import React from "react"
// import { Link } from "gatsby"
// import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import cx from "classnames"
import styles from "../styles/about.module.scss"
import ScrollDownArrow from "../components/scroll-down-arrow/scroll-down-arrow"

const AboutPage = () => (
  <div className={styles.bg}>
    <SEO title="About" />
    <div className={styles.spacer}></div>
    <section className={styles.about}>
      <div className={styles.intro}>
        <div className={styles.text}>
          <h1>About</h1>
          <p>
              Hi, I’m Antonio! I explore the intersection between design and technology. I'm
              a digital product designer, designing the next generation of digital experiences.
          </p>
          <p>
              I love creating interactive experiences, storytelling,
              skateboarding, guitars, cooking, and watching the sunset. 
              
              My style is influenced by skate, music and street culture.
              Video games play a big part for my love of storytelling, usability
              and interactive experiences.
          </p>
          <ScrollDownArrow url="/about/#grid" />
        </div>
        <div className={styles.image}>
          {/* <img
            src="https://res.cloudinary.com/antoniolee/image/upload/v1593125814/portrait-animation-bg_iu9cdz.gif"
            alt=""
          /> */}
          <video classname={styles.video} loop autoPlay muted playsInline>
            {/* <source src="https://res.cloudinary.com/antoniolee/video/upload/w_800,h_533,c_crop,f_auto,q_auto/v1593720612/drone_footy_hb9k20.mp4" /> */}
            <source src="https://res.cloudinary.com/antoniolee/video/upload/f_auto,q_auto/v1593720612/drone_footy_hb9k20.mp4" />
          </video>
        </div>
      </div>
      <div id="grid" className={styles.grid}>
        <div className={cx(styles.tile, styles.doubleExposure)}></div>
        <div className={cx(styles.tile, styles.tampalais)}></div>
        <div className={cx(styles.tile, styles.brother2 )}></div>
        <div className={cx(styles.tile, styles.futur)}></div>
        <div className={cx(styles.tile, styles.portrait)}></div>
        <div className={cx(styles.tile, styles.djing)}></div>
        <div className={cx(styles.tile, styles.galileo)}></div>
        <div className={cx(styles.tile, styles.meDancing)}></div>
        <div className={cx(styles.tile, styles.haleakala, styles.flipHorizontally)}></div>
        <div className={cx(styles.tile, styles.sunset)}></div>
        <div className={cx(styles.tile, styles.rockClimbing)}></div>
        <div className={cx(styles.tile, styles.babyLee)}></div>
      </div>
    </section>
  </div>
)

export default AboutPage
